import React, { useEffect, useReducer, useState,useContext } from "react";

import {
  Button,
  makeStyles,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  IconButton
} from "@material-ui/core";
import { AuthContext } from "../../context/Auth/AuthContext";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { Delete, DeleteOutline, Edit } from "@material-ui/icons";
import { toast } from "react-toastify";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { i18n } from "../../translate/i18n";
import moment from 'moment'
import AgendamentoModal from "../../components/AgendamentoModal";
import AgendamentoEdit from "../../components/AgendamentoEdit";
import AgendamentoDeleteDialog from "../../components/AgendamentoDeleteDialog";

const ListaAgendamento = () => {

  const useStyles = makeStyles(theme => ({
    mainPaper: {
      flex: 1,
      padding: theme.spacing(1),
      overflowY: "scroll",
      ...theme.scrollbarStyles,
    },
    customTableCell: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    mainContainer: {
      height: 600
    }
  }));
  const classes = useStyles()
  //const [searchParam, setSearchParam] = React.useState(false);
  const [dados, setDados] = useState([])
  const [WebhookOpen, setOpen] = React.useState(false);
  const [EditOpen, setEditOpen] = React.useState(false);
  const [EditData, setEditData] = React.useState({});
  const [DelOpen, setDelOpen] = React.useState(false);
  const [DelData, setDelData] = React.useState({});
  const [reload, setReload] = React.useState(false)
  const { user } = useContext(AuthContext);

  const { user: loggedInUser } = useContext(AuthContext);

  const handleDel = () => setOpen(true);
  const handleCloseDel = () => {
    setDelOpen(false);
    setReload(!reload)
  }
  const handleUpdate = () => setReload(!reload)
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setReload(!reload)
  }

  const handleEditClose = () => {
    setEditOpen(false);
    setReload(!reload)
  }


  useEffect(() => {
    const getData = async () => {
      let resp = await api.get('/scheduleList')
      console.log(resp)
      setDados(resp.data)
    }
    getData()
  }, [reload])

  const handleEdit = async (d) => {
   // console.log(d)
    setEditData(d)
    setEditOpen(true)
  }

  const handleDelete = async (d) => {
    setDelData(d)
    setDelOpen(true)
   // console.log(d)
  }


  //   const fetchSchedules = useCallback(async() => {
  //     try {
  //       const { data } = await api.get("/scheduleList/", {
  //         params: { searchParam, pageNumber },
  //       });

  //     } catch (err) {
  //       toastError(err);
  //     }
  //   }, [searchParam, pageNumber]);

  // useEffect(() => {
  //   const search = async () => {
  //     let resp = await api.post("/scheduleSearch", searchParam)
  //   }
  //   search()
  // },[searchParam])

  return (
    <MainContainer>
      <AgendamentoDeleteDialog
        open={DelOpen}
        onClose={handleCloseDel}
        data={DelData}
      >
      </AgendamentoDeleteDialog>
      <AgendamentoEdit
        dados={EditData}
        onClose={handleEditClose}
        open={EditOpen}
        update={handleUpdate}
      >

      </AgendamentoEdit>
      <AgendamentoModal
        Open={WebhookOpen}
        onClose={handleClose}
        Update={handleUpdate}
      >

      </AgendamentoModal>
      <MainHeader>
        <Title>Agendamento</Title>
        <MainHeaderButtonsWrapper>
          <TextField
            placeholder={i18n.t("contacts.searchPlaceholder")}
            type="search"
            //                value={searchParam}
            //                onChange={handleSearch}
            disabled={true}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "gray" }} />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpen}
          >
            Adicionar Agendamento
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper
        className={classes.mainPaper}
        variant="outlined"
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">Contato</TableCell>
              <TableCell align="center">Mensagem</TableCell>
              <TableCell align="center">Fila</TableCell>
              <TableCell align="center">Tipo</TableCell>
              <TableCell align="center">Data</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>{dados.map(data => (
              <TableRow key={data.id}>
                 {data.csv == null && <TableCell align="center">{data.numero}</TableCell>}
                {data.numero == null && <TableCell align="center">CSV</TableCell>} 
                <TableCell align="center" width={420}>{data.msg}</TableCell>
                {data.fila == null && <TableCell align="center">Sem Fila</TableCell>}
                {data.fila > 0 && <TableCell align="center">{loggedInUser.queues.map(e => (
                  <>{e.id == data.fila && <TableCell align="center">{e.name}</TableCell> && <>{data.nomeFila = e.name}</>}</>
                ))}</TableCell>}
                {data.emMassa == 0 && <TableCell align="center">Normal</TableCell>}
                {data.emMassa > 0 && <TableCell align="center">Massa</TableCell>}
                <TableCell align="center">{moment(data.date).format('DD/MM/YYYY HH:mm:ss')}</TableCell>
                {data.status == 'p' && <TableCell align="center">Pendente</TableCell>}
                {data.status == 's'  && <TableCell align="center">Enviado</TableCell>}
                {data.status == 'e'  && <TableCell align="center">Erro</TableCell>}
                <TableCell align="center">
                  {data.status == `p` && <IconButton
                    size='small'
                    onClick={e => handleEdit(data)}
                  >
                    <Edit />
                  </IconButton>}
                  {data.status == `s` && <IconButton
                    size='small'
                    disabled
                  >
                    <Edit />
                  </IconButton>}
                  {data.status == `e` && <IconButton
                    size='small'
                    disabled
                  >
                    <Edit />
                  </IconButton>}
                  <IconButton
                    size='small'
                    onClick={() => handleDelete(data)}
                  >
                    <Delete />

                  </IconButton>
                </TableCell>
              </TableRow>
            ))}</>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );

}

export default ListaAgendamento

