import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core"
import React, { useEffect, useState } from "react";
import api from "../../services/api";


const CampanhaDialog = ({ open, onClose, campanha }) => {

    const [totalContatos, setContatosTotal] = useState('')
    useEffect(() => {
        async function getData() {
            let d = await api.post(`/getCountStatusMsg`, campanha)
            if(!d.data.count){
                setContatosTotal(0)
            } else {
            setContatosTotal(d.data.count)
            }

            console.log(d.data)
        }
        if (open == true) {
            getData()
        }

    }, [open])

    const closeDialog = () => {
        setContatosTotal('')
        onClose()
    }

    return (
        <Dialog
            open={open}
            onClose={closeDialog}
        >
            <DialogTitle>
                Status Campanha {campanha.name}
            </DialogTitle>
            <DialogContent dividers>
                <Paper>
                    <Table size="medium">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">
                                    Mensagem
                                </TableCell>
                                <TableCell align="center">
                                    Total de Contatos
                                </TableCell>
                                <TableCell align="center">
                                    Mensagens Enviadas
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableCell align="center">
                                {campanha.mensagem}
                            </TableCell>
                            <TableCell align="center">
                                {campanha.numContatos}
                            </TableCell>
                            <TableCell align="center">
                                {totalContatos} ({totalContatos == 0 ? `(100%)` : `(${(totalContatos/campanha.numContatos)*100}%)` })
                            </TableCell>
                        </TableBody>
                    </Table>
                </Paper>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={closeDialog}
                    color="secondary"
                    //disabled={isSubmitting}
                    variant="outlined"
                >
                    Sair
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default CampanhaDialog
