import React, { useEffect, useReducer, useState } from "react";

import {
    Button,
    makeStyles,
    Paper,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Select,
    MenuItem,
    Tooltip,
    Typography,
    IconButton,
    Link,
    Container
} from "@material-ui/core";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";
import AddCampanha from "../../components/AddCampanha";
import api from "../../services/api";
import { ArchiveOutlined, CheckCircleOutline, Delete, DoneAll, Edit, ErrorOutline, PauseCircleFilled, PauseCircleFilledOutlined } from "@material-ui/icons";
import DeleteCampanha from "../../components/DeleteCampanha";
import AtivarCampanha from "../../components/AtivarCampanha";
import EditCampanha from "../../components/EditCampanha";
import AddCampanhaCsv from "../../components/AddCampanhaCsv";
import CampanhaDialog from "../../components/CampanhaDialog";
import DesativarCampanha from "../../components/DesativarCampanha";

const useStyles = makeStyles(theme => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
    customTableCell: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    tooltip: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        fontSize: theme.typography.pxToRem(14),
        border: "1px solid #dadde9",
        maxWidth: 450,
    },
    tooltipPopper: {
        textAlign: "center",
    },
}));

const Campanha = () => {

    const [campanhaOpen, setCampanhaOpen] = React.useState(false);
    const [delCampanhaOpen, setDelCampanhaOpen] = React.useState(false);
    const [ativarCampanhaOpen, setAtivarCampanha] = React.useState(false);
    const [desativarCampanhaOpen, setDesativarCampanha] = React.useState(false);
    const [campanhaDialogOpen, setAtivarCampanhaDialog] = React.useState(false);
    const [editarCampanhaOpen, setEditarCampanha] = React.useState(false);
    const [addCsvOpen, setAddCsv] = React.useState(false);
    const [reload, setReload] = React.useState(false)
    const [campanhas, setCampanhas] = React.useState([])
    const [dataLinha, setDataLinha] = React.useState([])

    const classes = useStyles()

    const handleCampanhaOpen = () => setCampanhaOpen(true);
    const handleCampanhaClose = () => {
        setCampanhaOpen(false);
        setReload(!reload)
    }

    const handleDelCampanhaClose = () => {
        setDelCampanhaOpen(false);
        setReload(!reload)
    }

    const handleCampanhaDialog = () => {
        setAtivarCampanhaDialog(false);
        setReload(!reload)
    }

    const handleAtivarCampanhaClose = () => {
        setAtivarCampanha(false);
        setReload(!reload)
    }

    const handleDesativarCampanhaClose = () => {
        setDesativarCampanha(false);
        setReload(!reload)
    }

    const handleEditarCampanhaClose = () => {
        setEditarCampanha(false);
        setReload(!reload)
    }
    const handleCsvClose = () => {
        setAddCsv(false);
        setReload(!reload)
    }

    const handleDelete = (c) => {
        setDataLinha(c)
        setDelCampanhaOpen(true)
    }

    const handleAtivar = (c) => {
        setDataLinha(c)
        setAtivarCampanha(true)
    }
    const handleDesativar = (c) => {
        setDataLinha(c)
        setDesativarCampanha(true)
    }

    const handleEditar = (c) => {
        setDataLinha(c)
        setEditarCampanha(true)
    }

    const handleCsv = (c) => {
        setDataLinha(c)
        setAddCsv(true)
    }

    const CustomToolTip = ({ title, content, children }) => {
        const classes = useStyles();

        return (
            <Tooltip
                arrow
                classes={{
                    tooltip: classes.tooltip,
                    popper: classes.tooltipPopper,
                }}
                title={
                    <React.Fragment>
                        <Typography gutterBottom color="inherit">
                            {title}
                        </Typography>
                        {content && <Typography>{content}</Typography>}
                    </React.Fragment>
                }
            >
                {children}
            </Tooltip>
        );
    };

    function pageRefresh() {
        var interval = 60000
        setTimeout(() => {
            setReload(!reload)
        }, interval);
    }

    useEffect(() => {
        async function getCampanha() {
            var res = await api.post("/getCampanhas")
            //console.log(res.data)
            setCampanhas(res.data)
            console.log(res.data)
        }
        getCampanha()
    }, [reload])

    useEffect(() => {
        pageRefresh()
    },[campanhas])
    
    return (
        <MainContainer>
            <CampanhaDialog
                open={campanhaDialogOpen}
                onClose={handleCampanhaDialog}
                campanha={dataLinha}
            />
            <AddCampanha
                open={campanhaOpen}
                onClose={handleCampanhaClose}
            />
            <DeleteCampanha
                open={delCampanhaOpen}
                onClose={handleDelCampanhaClose}
                data={dataLinha}
            />
            <DesativarCampanha
                open={desativarCampanhaOpen}
                onClose={handleDesativarCampanhaClose} 
                data={dataLinha}
            />
            <AtivarCampanha
                open={ativarCampanhaOpen}
                onClose={handleAtivarCampanhaClose}
                data={dataLinha}
            />
            <EditCampanha
                open={editarCampanhaOpen}
                onClose={handleEditarCampanhaClose}
                cData={dataLinha}
            />
            <AddCampanhaCsv
                open={addCsvOpen}
                onClose={handleCsvClose}
                c={dataLinha}
            />

            <MainHeader>
                <Title>Campanha</Title>
                <MainHeaderButtonsWrapper>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleCampanhaOpen}
                    >
                        Adicionar Campanha
                    </Button>
                </MainHeaderButtonsWrapper>
            </MainHeader>
            <Paper className={classes.mainPaper} variant="outlined">
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">
                                Nome
                            </TableCell>
                            <TableCell align="center">
                                Conexão
                            </TableCell>
                            <TableCell align="center">
                                Fila
                            </TableCell>
                            <TableCell align="center">
                                Status
                            </TableCell>
                            <TableCell align="center">
                                Data
                            </TableCell>
                            <TableCell align="center">
                                Horário
                            </TableCell>
                            <TableCell align="center">
                                Ações
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                            {campanhas.map(c => (
                                <TableRow key={c.id}>
                                    <TableCell align="center">
                                        <Link component='button'
                                            variant="body2"
                                            onClick={() => {
                                                setDataLinha(c)
                                                setAtivarCampanhaDialog(true)
                                            }}
                                        >{c.name}</Link>
                                    </TableCell>
                                    <TableCell align="center">{c.nomeWhats}</TableCell>
                                    {c.nomeFila === null ? <TableCell>Sem fila</TableCell> : <TableCell align="center">{c.nomeFila}</TableCell>}
                                    {c.status == `i` && <TableCell align="center">
                                        <CustomToolTip
                                            title={'Inativo'}
                                            content={'A Campanha está atualmente inativa.'}
                                        >
                                            <ErrorOutline style={{ color: 'red' }} />
                                        </CustomToolTip>
                                    </TableCell>}
                                    {c.status == `p` && <TableCell align="center">
                                        <CustomToolTip
                                            title={'Pendente'}
                                            content={'A Campanha está pendente, esperando o horário e/ou data de funcionamento.'}
                                        >
                                            <PauseCircleFilledOutlined style={{ color: 'darkgoldenrod' }} />
                                        </CustomToolTip>
                                    </TableCell>}
                                    {c.status == `f` && <TableCell align="center">
                                        <CustomToolTip
                                            title={'Finalizada'}
                                            content={'A Campanha está finalizada, não existe mais leads para serem disparados.'}
                                        >
                                            <DoneAll style={{ color: 'green' }} />
                                        </CustomToolTip>
                                    </TableCell>}
                                    {c.status == `a` && <TableCell align="center">
                                        <CustomToolTip
                                            title={'Ativa'}
                                            content={'A Campanha está ativa.'}
                                        >
                                            <CheckCircleOutline style={{ color: 'green' }} />
                                        </CustomToolTip>
                                    </TableCell>}
                                    <TableCell align='center'>{c.dataInicial}-{c.dataFinal}</TableCell>
                                    <TableCell align='center'>{c.horarioInicial}-{c.horarioFinal}</TableCell>
                                    <TableCell align="center">
                                        {c.status == `i` && <IconButton
                                            size='small'
                                            onClick={() => handleAtivar(c)}
                                        >
                                            <CheckCircleOutline style={{ color: 'green' }} />
                                        </IconButton>
                                        }

                                        {c.status == `a` && <IconButton
                                            size='small'
                                            onClick={() => handleDesativar(c)}
                                        >
                                            <ErrorOutline style={{ color: 'red' }} />
                                        </IconButton>
                                        }
                                        {c.status == `p` && <IconButton
                                            size='small'
                                            onClick={() => handleDesativar(c)}
                                        >
                                            <ErrorOutline style={{ color: 'red' }} />
                                        </IconButton>
                                        }
                                        {c.status == `f` && <IconButton
                                            size='small'
                                            onClick={() => handleDesativar(c)}
                                        >
                                            <ErrorOutline style={{ color: 'red' }} />
                                        </IconButton>
                                        }                                        

                                        <IconButton
                                            size="small"
                                            onClick={() => handleEditar(c)}
                                        >
                                            <Edit />
                                        </IconButton>

                                        <IconButton
                                            size='small'
                                            onClick={() => handleDelete(c)}
                                        >
                                            <Delete />
                                        </IconButton>
                                        <IconButton
                                            size='small'
                                            onClick={() => handleCsv(c)}
                                        >
                                            <ArchiveOutlined />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </>
                    </TableBody>
                </Table>
            </Paper>
            <Container>
            <Typography color="primary">A pagina atualiza a cada 1 minuto, para acessar as estatísticas da campanha clique no nome dela.</Typography>
            </Container>
        </MainContainer>
    )
}

export default Campanha